import Cookies from 'js-cookie';

const state = {
  sidebar: {
    opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
    withoutAnimation: false,
  },
  device: 'desktop',
  size: Cookies.get('size') || 'medium',
};

const mutations = {

  /* **** TOGGLE_SIDEBAR **** */
  TOGGLE_SIDEBAR: (state) => {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;
    if(state.sidebar.opened) {
      Cookies.set('sidebarStatus', 1);
    } else {
      Cookies.set('sidebarStatus', 0);
    }
  },
  
  /* **** CLOSE_SIDEBAR **** */
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
    Cookies.set('sidebarStatus', 0);
  },

  /* **** TOGGLE_DEVICE **** */
  TOGGLE_DEVICE: (state, device) => {
    state.device = device;
  },
  
  /* **** SET_SIZE **** */
  SET_SIZE: (state, size) => {
    state.size = size;
    Cookies.set('size', size);
  },

};

const actions = {
  
  /* **** toggleSideBar **** */
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR');
  },

  /* **** closeSideBar **** */
  closeSideBar({ commit }, { withoutAnimation }) {
    commit('CLOSE_SIDEBAR', withoutAnimation);
  },

  /* **** toggleDevice **** */
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device);
  },
  
  /* **** setSize **** */
  setSize({ commit }, size) {
    commit('SET_SIZE', size);
  },

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
