const getters = {
  
  /* **** sidebar **** */
  sidebar: state => state.app.sidebar,

  /* **** userId **** */
  userId: state => state.digitender.user.id,
  
  /* **** userMode **** */
  userMode: state => state.digitender.user.card_id ? 1 : state.digitender.user.active_card_id ? 2 : 3,

  /* **** userPermit **** */
  userHavePermit: state => permissions => permissions.indexOf('*') > -1 ||
    permissions.some(p => state.digitender.user.permissions.indexOf(p) > -1),

};

export default getters;
