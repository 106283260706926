import defaultSettings from '@/settings';

const { fixedHeader } = defaultSettings;

const state = {
  fixedHeader: fixedHeader,
};

const mutations = {

  /* **** CHANGE_SETTING **** */
  CHANGE_SETTING: (state, { key, value }) => {
    state[key] = value;
  },

};

const actions = {
  
  /* **** changeSetting **** */
  changeSetting({ commit }, data) {
    commit('CHANGE_SETTING', data);
  },
  
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
