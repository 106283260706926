export default {

  install: function(Vue) {

    /* **** $filterBuild **** */
    Vue.$filterBuild = Vue.prototype.$filterBuild = function(par) {
      if(typeof methods[par] !== 'undefined')
        methods[par].build.call(this);
    };

    /* **** $filterUpdate **** */
    Vue.$filterUpdate = Vue.prototype.$filterUpdate = function(par) {
      if(typeof methods[par] !== 'undefined')
        methods[par].update.call(this);
    };

    /* **** $filter **** */
    Vue.$filter = Vue.prototype.$filter = function(par, id, val) {
      if(typeof methods[par] !== 'undefined')
        if(typeof val !== 'undefined') {
          methods[par].set.call(this, id, val);
        }
        else {
          methods[par].check.call(this, id);
        }
    };

    const methods = {

      /* **** cards **** */
      cards: {

        /* **** build **** */
        build() {
          const temp1 = {},
                temp2 = [];
          this.$sup.cards_ids.forEach(
            (id) => {
              const k = id.toString();
              if(Object.prototype.hasOwnProperty.call(this.$filters.cards.cache, k)) {
                // уже есть в кэше
                temp1[k] = true;
              }
              else {
                // добавить в кэш
                temp2.push(k);
              }
            }
          );
          Object.keys(this.$filters.cards.cache).filter(k => !Object.prototype.hasOwnProperty.call(temp1, k)).forEach(
            (k) => { delete this.$filters.cards.cache[k]; }
          );
          temp2.forEach(
            (k) => { this.$filters.cards.cache[k] = true; }
          );
          methods.cards.update.call(this);
        },

        /* **** update **** */
        update() {
          if(this.$user.active_card_id) {
            this.$filters.cards.ids.splice(0, this.$filters.cards.ids.length, this.$user.active_card_id);
          }
          else {
            this.$filters.cards.ids.splice(0, this.$filters.cards.ids.length);
            Array.prototype.push.apply(
              this.$filters.cards.ids,
              Object.keys(this.$filters.cards.cache).filter(k => this.$filters.cards.cache[k]).map(k => parseInt(k))
            );
          }
        },

        /* **** set **** */
        set(id, val) {
          const k = id.toString();
          if(Object.prototype.hasOwnProperty.call(this.$filters.cards.cache, k)) {
            this.$filters.cards.cache[k] = val;
            if(val) {
              this.$filters.cards.ids.push(id);
            }
            else {
              const i = this.$filters.cards.ids.indexOf(id);
              this.$filters.cards.ids.splice(i, 1);
            }
          }
        },

        /* **** check **** */
        check(id) {
          return this.$filters.cards.cache[id.toString()];
        },

      },

    };

  },

};
