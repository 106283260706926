import Layout from '@/layout';

const router = [
  /*{
    path: '/digitender/users',
    component: Layout,
    children: [
      {
        path: 'index',
        component: () => import('@/views/digitender/users'),
        name: 'DigitenderUsers',
        meta: { title: 'Сотрудники', icon: 'tab' },
      },
    ],
  },*/
];

export default router;
