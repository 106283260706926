import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

import Layout from '@/layout';

import DigitenderManager from './modules/digitender-manager.js';
import DigitenderCard from './modules/digitender-card.js';

export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index'),
      },
    ],
  },
  {
    path: '/auth',
    component: () => import('@/views/auth/index'),
    hidden: true,
  },
  {
    path: '/404',
    component: () => import('@/views/error/404'),
    hidden: true,
  },
  {
    path: '/403',
    component: () => import('@/views/error/403'),
    hidden: true,
  },
  {
    path: '/',
    component: Layout,
    redirect: '/monitor',
    children: [
      {
        path: 'monitor',
        component: () => import('@/views/monitor/index'),
        meta: { title: 'Монитор', icon: 'chart-bar-graph' },
      },
    ],
  },
  ...DigitenderManager,
  ...DigitenderCard,
  {
    path: '*',
    redirect: '/404',
    hidden: true,
  },

];

export const asyncRoutes = [];

const createRouter = () => new Router({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes,
});

const router = createRouter();


router.beforeEach(
  (to, from, next) => {
    const root = router.app.$root;
    console.log('R: RouteTo', to.path);
    console.log('-- StartState', root.$states.start);
    console.log('-- RedirectState', root.$client.redirect.path, root.$client.redirect.query);
    if(root.$client.redirect.path === '') {
      root.$client.redirect.path = to.path;
      root.$client.redirect.query = JSON.parse(JSON.stringify(to.query));
    }
    if(root.$states.start) {
      if(root.$user.id) {
        if(to.path === '/auth') {
          next({ path: '/' });
        }
        else {
          next();
        }
      }
      else {
        if(to.path === '/auth') {
          next();
        }
        else {
          next({ path: '/auth' });
        }
      }
    }
    else {
      if(to.path === '/auth') {
        next();
      }
      else {
        next({ path: '/auth' });
      }
    }
  }
);


export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher;
}

export default router;
