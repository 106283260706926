<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <router-view :key="key" />
    </transition>
  </section>
</template>

<script>
export default {
  
  name: 'AppMain',

  computed: {

    /* **** key **** */
    key() {
      return this.$route.path;
    },

  },

};
</script>

<style>
.app-main {
  min-height: calc(100vh - 50px);
  width: 100%;
  position: relative;
  overflow: hidden;
}

.fixed-header + .app-main {
  padding-top: 50px;
}

.hasTagsView .app-main {
  min-height: calc(100vh - 84px);
}

.hasTagsView .fixed-header + .app-main {
  padding-top: 84px;
}

.el-popup-parent--hidden .fixed-header {
  padding-right: 15px;
}

/* fade-transform */

.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all .4s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
