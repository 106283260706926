import router from '@/router';
import store from '@/store';

import getPageTitle from '@/utils/get-page-title';

const whiteList = [ '/login' ];

router.beforeEach(async(to, from, next) => {

  document.title = getPageTitle(to.meta.title);

  if(store.getters.userId) {
    if(to.path === '/login') {
      next({ path: '/' });
    }
    else {
      if((!to.permissions || store.getters.userPermit(to.permissions)) && (!to.mode || to.mode.indexOf(store.getters.userMode) > -1)) {
        next();
      }
      else {
        next({ path: '/403' });
      }
    }
  }
  else {
    if (whiteList.indexOf(to.path) > -1) {
      next();
    } else {
      next('/login?redirect=' + to.path);
    }
  }

});
