<template>
  <div>
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :unique-opened="false"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="false"
        mode="vertical"
      >
        <sidebar-item v-for="route in constantRoutes" :key="route.path" :item="route" :base-path="route.path" />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SidebarItem from './SidebarItem'
import variables from '@/styles/variables.scss'
import { constantRoutes } from '@/router'

export default {
  components: { SidebarItem },
  data() {
    return {
      constantRoutes: constantRoutes
    }
  },
  computed: {
    ...mapGetters([
      'permission_routes',
      'sidebar'
    ]),
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    variables() {
      return variables
    },
    isCollapse() {
      return !this.sidebar.opened
    },
  }
}
</script>

<style>
#app .main-container {
  min-height: 100%;
  transition: margin-left 0.25;
  margin-left: var(--sidebar-width);
  position: relative;
}

#app .sidebar-container {
  transition: width 0.25;
  width: var(--sidebar-width) !important;
  background-color: var(--menu-bg);
  height: 100%;
  position: fixed;
  font-size: 0px;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  overflow: hidden;
}

#app .sidebar-container .horizontal-collapse-transition {
  transition: 0s width ease-in-out, 0s padding-left ease-in-out, 0s padding-right ease-in-out;
}

#app .sidebar-container .scrollbar-wrapper {
  overflow-x: hidden !important;
}

#app .sidebar-container .el-scrollbar__bar.is-vertical {
  right: 0px;
}

#app .sidebar-container .el-scrollbar {
  height: 100%;
}

#app .sidebar-container .is-horizontal {
  display: none;
}

#app .sidebar-container a {
  display: inline-block;
  width: 100%;
  overflow: hidden;
}

#app .sidebar-container .svg-icon {
  margin-right: 16px;
}

#app .sidebar-container .el-menu {
  border: none;
  height: 100%;
  width: 100% !important;
}

#app .sidebar-container .submenu-title-noDropdown:hover,
#app .sidebar-container .el-submenu__title:hover {
  background-color: var(--menu-hover) !important;
}

#app .sidebar-container .is-active > .el-submenu__title {
  color: var(--submenu-active-text) !important;
}

#app .sidebar-container .nest-menu .el-submenu > .el-submenu__title,
#app .sidebar-container .el-submenu .el-menu-item {
  min-width: var(--sidebar-width) !important;
  background-color: var(--submenu-bg) !important;
}

#app .sidebar-container .nest-menu .el-submenu > .el-submenu__title:hover,
#app .sidebar-container .el-submenu .el-menu-item:hover {
  background-color: var(--submenu-hover) !important;
}

#app .hideSidebar .sidebar-container {
  width: 54px !important;
}

#app .hideSidebar .main-container {
  margin-left: 54px;
}

#app .hideSidebar .submenu-title-noDropdown {
  padding: 0 !important;
  position: relative;
}

#app .hideSidebar .submenu-title-noDropdown .el-tooltip {
  padding: 0 !important;
}

#app .hideSidebar .submenu-title-noDropdown .el-tooltip .svg-icon {
  margin-left: 20px;
}

#app .hideSidebar .el-submenu {
  overflow: hidden;
}

#app .hideSidebar .el-submenu > .el-submenu__title {
  padding: 0 !important;
}

#app .hideSidebar .el-submenu > .el-submenu__title .svg-icon {
  margin-left: 20px;
}

#app .hideSidebar .el-submenu > .el-submenu__title .el-submenu__icon-arrow {
  display: none;
}

#app .hideSidebar .el-menu--collapse .el-submenu > .el-submenu__title > span:not(.micon) {
  height: 0;
  width: 0;
  overflow: hidden;
  visibility: hidden;
  display: inline-block;
}

#app .el-menu--collapse .el-menu .el-submenu {
  min-width: var(--sidebar-width) !important;
}

#app .mobile .main-container {
  margin-left: 0px;
}

#app .mobile .sidebar-container {
  transition: transform 0.28s;
  width: var(--sidebar-width) !important;
}

#app .mobile.hideSidebar .sidebar-container {
  pointer-events: none;
  transition-duration: 0.3s;
  transform: translate3d(calc(-1 * var(--sidebar-width)), 0, 0);
}

#app .withoutAnimation .main-container, #app .withoutAnimation .sidebar-container {
  transition: none;
}

.el-menu--vertical > .el-menu .svg-icon {
  margin-right: 16px;
}

.el-menu--vertical .nest-menu .el-submenu > .el-submenu__title:hover, .el-menu--vertical .el-menu-item:hover {
  background-color: var(--menu-hover) !important;
}

.el-menu--vertical > .el-menu--popup {
  max-height: 100vh;
  overflow-y: auto;
}

.el-menu--vertical > .el-menu--popup::-webkit-scrollbar-track-piece {
  background: #d3dce6;
}

.el-menu--vertical > .el-menu--popup::-webkit-scrollbar {
  width: 6px;
}

.el-menu--vertical > .el-menu--popup::-webkit-scrollbar-thumb {
  background: #99a9bf;
  border-radius: 20px;
}

.micon {
  color: #ffffff;
  font-size: 18px;
}
</style>
