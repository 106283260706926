<template>
  <div :class="classes" class="app-wrapper">
    <div v-if="device === 'mobile' && sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
    <sidebar class="sidebar-container" />
    <div class="main-container">
      <div :class="{ 'fixed-header': fixedHeader }">
        <navbar />
      </div>
      <app-main />
    </div>
  </div>
</template>

<script>
import { AppMain, Navbar, Sidebar } from './components';
import ResizeMixin from './mixin/resize-handler';
import { mapState } from 'vuex';

export default {

  name: 'Layout',

  components: {
    AppMain,
    Navbar,
    Sidebar,
  },
  
  mixins: [ ResizeMixin ],

  computed: {
    
    /* **** sidebar, device **** */
    ...mapState({
      sidebar: state => state.app.sidebar,
      device: state => state.app.device,
      fixedHeader: state => state.settings.fixedHeader,
    }),

    /* **** classes **** */
    classes() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
      };
    },

  },

  methods: {
    
    /* **** handleClickOutside **** */
    handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', { withoutAnimation: false });
    },
    
  },

}
</script>

<style>
.app-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.app-wrapper.mobile.openSidebar {
  position: fixed;
  top: 0;
}

.drawer-bg {
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
  background-color: rgb(0, 0, 0);
  opacity: 0.3;
  z-index: 999;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - var(--sidebar-width));
  transition: width 0.25s;
}

.hideSidebar .fixed-header {
  width: calc(100% - 54px);
}

.mobile .fixed-header {
  width: 100%;
}
</style>
