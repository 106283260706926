import Vue from 'vue';

import Filter from './filter.js';
import Cache from './cache.js';
import Connect from './connect.js';
import Auth from './auth.js';
import Communication from './communication.js';


export function start(options) {

  /* **** $bus **** */

  const bus = new Vue();

  bus.install = function() {
    Object.defineProperty(Vue.prototype, '$bus', { get() { return bus; } });
  };

  /* **** $user **** */

  const user = new Vue(
    {
      data: {
        id: 0,
        name: '',
        email: '',
        chief_id: 0,
        chief_name: '',
        position: '',
        phone: '',
        card_id: 0,
        active_card_id: 0,
        roles_ids: [],
        roles: [],
        permissions: [],
        companies_ids: [],
        companies: [],
        companies_enitre: 0,
      },
      computed: {
        mode() {
          return this.card_id ? 1 : this.active_card_id ? 3 : 2;
        },
      },
    },
  );

  user.install = function() {
    Object.defineProperty(Vue.prototype, '$user', { get() { return user; } });
  };

  /* **** $sup **** */

  const sup = new Vue(
    {
      data: {
        cards_ids: [],
        cards: [],
        subordinates_ids: [],
        subordinates: [],
        performers_ids: [],
        performers: [],
      },
    },
  );

  sup.install = function() {
    Object.defineProperty(Vue.prototype, '$sup', { get() { return sup; } });
  };

  /* **** $card **** */

  const card = new Vue(
    {
      data: {
        id: 0,
        name: '',
        settings: {
          templates_mutual: 0,
        },
        manager_id: 0,
        manager_name: '',
        flag_lock: 0,
      },
    }
  );

  card.install = function() {
    Object.defineProperty(Vue.prototype, '$card', { get() { return card; } });
  };

  /* **** $auth **** */

  const auth = new Vue(
    {
      data: {
        token: '',
        token_time: '',
      }
    }
  );

  auth.install = function() {
    Object.defineProperty(Vue.prototype, '$auth', { get() { return auth; } });
  };

  /* **** $states **** */

  const states = new Vue(
    {
      data: {
        start: false,
        welcome: 0,
        storage: false,
        connect: false,
        offline: false,
        upgrade: false,
        loading: false,
      },
    }
  );

  states.install = function() {
    Object.defineProperty(Vue.prototype, '$states', { get: function() { return states; } });
  };


/* **** $client **** */

  const client = new Vue(
    {
      data: {
        agent: options.agent,
        version: options.version,
        wlc: 'https://welcome.digitender.ru:5000',
        api: '',
        env: {},
        redirect: {
          path: '',
          query: null,
        },
      },
    }
  );

  client.install = function() {
    Object.defineProperty(Vue.prototype, '$client', { get: function() { return client; } });
  };

  /* **** $notifications **** */

  const notifications = new Vue(
    {
      data: {
        pool: [],
        amount: 0,
      },
    }
  );

  notifications.install = function() {
    Object.defineProperty(Vue.prototype, '$notifications', { get: function() { return notifications; } });
  };

  /* **** $messages **** */

  const messages = new Vue(
    {
      data: {
        manager: 0,
        lawyer: 0,
        task: 0,
        user: 0,
      },
    }
  );

  messages.install = function() {
    Object.defineProperty(Vue.prototype, '$messages', { get: function() { return messages; } });
  };

  /* **** $filters **** */

  const filters = new Vue(
    {
      data: {
        cards: {
          cache: {},
          ids: [],
        },
      },
    }
  );

  filters.install = function() {
    Object.defineProperty(Vue.prototype, '$filters', { get: function() { return filters; } });
  };

  /* **** **** */

  Vue.use(bus);
  Vue.use(user);
  Vue.use(sup);
  Vue.use(card);
  Vue.use(auth);
  Vue.use(states);
  Vue.use(client);
  Vue.use(notifications);
  Vue.use(messages);
  Vue.use(filters);

  Vue.use(Filter);
  Vue.use(Cache);
  Vue.use(Connect);
  Vue.use(Auth);
  Vue.use(Communication);

}
