const attempts = 2;
const welcome_control = 3600000;
//const socket_attempts = 10;

const pool = [];
let connect;
//init_socket = function () {
//}

let init_socket = function  () {
  
    let params={
      url: this.$client.api + '/ws',
      token: this.$auth.token,
      onConnect: () => {
        clearInterval(connect);
      },
      onDisconnect: () => true,
      onNotify: this.$notify,

    }
    this.$initSocket(params)
    connect=setInterval(
      ()=>{
        //console.log('Try...');
        init_socket.call(this);
      },5000

    )
}
export default {

  install: function(Vue) {

    /* **** $communication **** */
    Vue.$communication = Vue.prototype.$communication = function(protocols) {
      
      protocols.forEach(
        (p) => {
          switch(p) {
            case 'socket':
              init_socket.call(this);


              break;
            case 'push':
              this.$initFcm({
                onConnect: this.$register,
                onNotify: this.$notify,
              });
              break;
          }
        }
      );
    };

    /* **** $notify **** */
    Vue.$notify = Vue.prototype.$notify = function(notification) {
      this.$bus.$emit('refresh');
    };

    /* **** $register **** */
    Vue.$register = Vue.prototype.$register = function(token) {
      this.$client.env.device_token = token;
      this.$request({
        path: '/user/register',
        data: {
          device_id: this.$client.env.device_id,
          device_info: this.$client.env.device_info,
          device_token: this.$client.env.device_token,
        },
        call: [],
        cache: false
      });
    };

    /* **** $supplement **** */
    Vue.$supplement = Vue.prototype.$supplement = function(records, callback) {
      this.$request({
        path: '/user/supplement',
        data: { records: records },
        call: [ methods.supplement, this, callback ],
        cache: false
      });
    };

    /* **** $request **** */
    Vue.$request = Vue.prototype.$request = function(args) {
      args._t = new Date(Date.now()).getTime();
      args._rid =
        args._t.toString() +
        ('0000000' + Math.random().toString().substr(2, 8)).slice(-8) +
        ('00000000000' + this.$user.id.toString()).slice(-12);
      pool.push(args);
      methods.request.call(this);
    };

    const methods = {

      /* **** supplement **** */
      supplement(status, data, callback) {
        if(status == 200)
          this.$supSet(data);
        if(callback)
          callback();
      },

      /* **** request **** */
      request() {
        if(!this.$states.loading && pool.length) {
          this.$states.loading = true;
          const args = pool.shift();
          if(
            this.$states.welcome &&
            args._t < this.$states.welcome + welcome_control
          ) {
            methods.requestSend.call(this, args);
          }
          else {
            methods.ajaxSend({
              url: this.$client.wlc + '/init',
              data: { agent: this.$client.agent, version: this.$client.version },
              call: [ methods.init, this, args ],
              attempts: attempts
            });
          }
        }
      },

      /* **** init **** */
      init(status, data, args) {
        if(
          status == 200 &&
          typeof data.api == 'string' &&
          data.api
        ) {
          if(
            typeof data.upgrade == 'boolean' &&
            data.upgrade
          ) {
            this.$states.upgrade = true;
          }
          else {
            this.$states.welcome = args._t;
            if(
              this.$client.api != data.api
            ) {
              //this.$socketClose();
              this.$client.api = data.api;
            }
            methods.requestSend.call(this, args);
          }
        }
        else {
          this.$states.welcome = 0;
          this.$client.api = '';
          methods.requestFinish.call(this, status, data, args);
        }
      },

      /* **** requestSend **** */
      requestSend(args) {
        args.path = args.path.replace(/^\/*/, '/');
        args.data._token = this.$auth.token;
        args.data._rid = args._rid;
        methods.ajaxSend({
          url: this.$client.api + args.path,
          data: args.data,
          call: [ methods.establish, this, args ],
          attempts: attempts
        });
      },

      /* **** establish **** */
      establish(status, data, args) {
        this.$authSet(data);
        if(status >= 200 && status < 500 && this.$user.id) {
          //this.$socketOpen([ methods.requestResponse, this, status, data, args, socket_attempts ]);
          methods.requestResponse.call(this, status, data, args);
        }
        else{
          methods.requestResponse.call(this, status, data, args);
        }
      },

      /* **** requestResponse **** */
      requestResponse(status, data, args) {
        if(this.$states.storage && args.cache) {
          if(status == 200 && this.$user.id) {
            methods.requestSave.call(this, args.path, data);
          }
          else if((status >= 50 && status < 100) || status >= 500) {
            const c = methods.requestLoad.call(this, args.path);
            status = c[0];
            if(typeof data._alert == 'string' && data._alert) {
              c[1]._alert = data._alert;
              if(!this.$states.offline)
                c[1]._alert += ' Загружаются сохраненные данные.';
            }
            data = Object.assign(data, c[1]);
          }
        }
        methods.requestFinish.call(this, status, data, args);
      },

      /* **** requestSave **** */
      requestSave(path, data) {
        const d = {};
        Object.keys(data)
          .filter(
            (k) => { return k.charAt(0) != '_'; }
          )
          .forEach(
            (k) => { d[k] = data[k]; }
          );
        this.$cacheSet(
          this.$user.id.toString() + '__' + path.replace(/\//g, '_'),
          JSON.stringify(d),
          data._time
        );
      },

      /* **** requestLoad **** */
      requestLoad(path) {
        const result = [ 44, {} ];
        const d = this.$cacheGet(
          this.$user.id.toString() + '__' + path.replace(/\//g, '_')
        );
        if(d) {
          result[0] = 20;
          result[1] = JSON.parse(d);
        }
        return result;
      },

      /* **** requestFinish **** */
      requestFinish(status, data, args) {
        const root = this;
        setTimeout(
          () => {
            root.$states.loading = false;
            if(typeof data._alert == 'string' && data._alert)
              root.$bus.$emit('alert', data._alert);
            if(typeof data._notification == 'object' && typeof data._notification.message == 'string')
              root.$bus.$emit('notify', data._notification);
            if(
              typeof args.call == 'object' &&
              typeof args.call[0] == 'function'
            ) {
              args.call[0].call(
                args.call[1],
                status,
                data,
                typeof args.call[2] != 'undefined' ? args.call[2] : null
              );
            }
            methods.request.call(root);
          },
          10
        );
      },

      /* **** ajaxSend **** */
      ajaxSend(req) {
        var xhr = new XMLHttpRequest();
        xhr.open('POST', req.url);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
        xhr.timeout = 7000;
        xhr.ontimeout = function() {
          methods.ajaxFinish(req, 54, { _alert: 'Незавершённый запрос.' });
        };
        xhr.onabort = function() {
          methods.ajaxFinish(req, 50, { _alert: 'Незавершённый запрос.' });
        };
        xhr.onerror = function() {
          methods.ajaxFinish(req, 50, { _alert: 'Непредвиденная ошибка.' });
        };
        xhr.onload = function() {
          var data = null;
          if(xhr.responseText) {
            try {
              data = JSON.parse(xhr.responseText);
            }
            catch(e) {
              methods.ajaxFinish(req, 50, { _alert: 'Неверный ответ сервера.' });
              return;
            }
            methods.ajaxFinish(req, xhr.status, data);
          }
          else {
            methods.ajaxFinish(req, 50, { _alert: 'Непредвиденный ответ сервера.' });
          }
        }
        xhr.send(JSON.stringify(req.data));
      },

      /* **** ajaxFinish **** */
      ajaxFinish(req, status, data) {
        req.attempts--;
        if(
          req.attempts < 1 || (status >= 100 && status < 500)
        ) {
          req.call[0].call(req.call[1], status, data, req.call[2]);
        }
        else {
          methods.ajaxSend(req);
        }
      },

    };

  },

};
