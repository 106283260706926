const state = {
  user: {
    id: 1,
    name: 'Админ',
    permissions: [ 'access_full', 'card_admin' ],
    card_id: 0,
    active_card_id: 1,
  },
};

export default {
  namespaced: true,
  state,
};
