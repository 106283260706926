import Layout from '@/layout';

const router = [
  {
    path: '/digitender/clients/cards',
    component: Layout,
    redirect: '/digitender/clients/cards/list',
    name: 'DigitenderClients',
    meta: { title: 'Клиенты', icon: 'business-man' },
    children: [
      {
        path: 'list',
        component: () => import('@/views/digitender/cards-list'),
        name: 'DigitenderClientsCardsList',
        meta: { title: 'Карты', icon: 'files-stack' },
      },
      {
        path: 'view/:card_id',
        props: true,
        component: () => import('@/views/digitender/cards-view'),
        name: 'DigitenderClientsCardsView',
        meta: { title: 'Карта' },
        hidden: true,
      },
      {
        path: ':card_id/users/add',
        props: true,
        component: () => import('@/views/digitender/cards-users-add'),
        name: 'DigitenderClientsCardsUsersAdd',
        meta: { title: 'Добавить пользователя' },
        hidden: true,
      },
      {
        path: 'users/view/:user_id',
        props: true,
        component: () => import('@/views/digitender/cards-users-view'),
        name: 'DigitenderClientsCardsUsersView',
        meta: { title: 'Пользователь' },
        hidden: true,
      },
      {
        path: 'add',
        component: () => import('@/views/digitender/cards-add'),
        name: 'DigitenderClientsCardsAdd',
        meta: { title: 'Добавить карту', icon: 'ui-add' },
      },
      {
        path: 'users',
        component: () => import('@/views/digitender/cards-users-list'),
        name: 'DigitenderСlientsUsersList',
        meta: { title: 'Пользователи', icon: 'users-alt-2' },
      },
    ],
  },
  {
    path: '/digitender/managers',
    component: Layout,
    children: [
      {
        path: 'index',
        component: () => import('@/views/digitender/managers-list'),
        name: 'DigitenderManagers',
        meta: { title: 'Менеджеры', icon: 'support' },
      },
      {
        path: 'view/:user_id',
        props: true,
        component: () => import('@/views/digitender/managers-view'),
        name: 'DigitenderManagersView',
        meta: { title: 'Менеджер' },
        hidden: true,
      },
      {
        path: 'add',
        component: () => import('@/views/digitender/managers-add'),
        name: 'DigitenderManagersAdd',
        meta: { title: 'Добавить менеджера', icon: 'ui-add' },
        hidden: true,
      },
    ],
  },
  /*{
    path: '/digitender/chats',
    component: Layout,
    redirect: '/digitender/chats/manager',
    name: 'DigitenderChats',
    meta: { title: 'Чаты', icon: 'ui-chat' },
    children: [
      {
        path: 'manager',
        component: () => import('@/views/digitender/chats'),
        name: 'DigitenderChatsManager',
        meta: { title: 'С менеджером' },
        hidden: true,
      },
    ],
  },*/
  /*{
    path: '/digitender/roles',
    component: Layout,
    redirect: '/digitender/roles/list',
    name: 'DigitenderRoles',
    meta: { title: 'Роли', icon: 'tab' },
    children: [
      {
        path: 'list',
        component: () => import('@/views/digitender/roles-list'),
        name: 'DigitenderRolesList',
        meta: { title: 'Список ролей' },
      },
      {
        path: 'permissions',
        component: () => import('@/views/digitender/roles-permissions'),
        name: 'DigitenderRolesPermissions',
        meta: { title: 'Пермиты' },
      },
    ],
  },*/
];

export default router;
