<template>
  <div class="navbar">
    <hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />
    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />
    <div class="profile">
      <div class="avatar"><span class="icofont-user"></span></div>
      <el-dropdown @command="operate">
        <span class="el-dropdown-link">{{ $user.name }}<i class="el-icon-arrow-down el-icon--right"></i></span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item icon="el-icon-setting" command="profile">Профиль</el-dropdown-item>
          <el-dropdown-item icon="el-icon-user" command="logout">Выйти</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import Hamburger from '@/components/Hamburger';
import { mapGetters } from 'vuex';

export default {

  components: {
    Breadcrumb,
    Hamburger,
  },

  computed: {
    
    /* **** sidebar, device **** */
    ...mapGetters([
      'sidebar',
    ]),

  },

  methods: {
    
    /* **** toggleSideBar **** */
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    },
    
    operate(operation) {
      if(operation == 'logout') {
        this.$request({
          path: '/logout',
          data: {},
          call: [ null, this ],
          cache: false,
        });
      }
    },

  },

}
</script>

<style>
.navbar {
  height: 50px;
  position: relative;
  background: #ffffff;
  overflow: hidden;
  z-index: 1;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05), 0px 1px 0px rgba(0, 0, 0, 0.05);
}

.navbar .hamburger-container {
  line-height: 46px;
  height: 100%;
  float: left;
  cursor: pointer;
  transition: background 0.3s;
  -webkit-tap-highlight-color: transparent;
}

.navbar .hamburger-container:hover {
  background: rgba(0, 0, 0, .025);
}

.navbar .breadcrumb-container {
  float: left;
}

.navbar .profile {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 50px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  padding-right: 20px;
}

.navbar .profile .avatar {
  background-color: rgb(60, 103, 88);
  color: #ffffff;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 16px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-dropdown-menu__item i {
  margin-right: 16px !important;
}

</style>
