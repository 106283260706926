import store from '@/store';

const { body } = document;

const WIDTH = 992;

export default {
  
  watch: {

    $route() {
      if(this.device === 'mobile' && this.sidebar.opened)
        store.dispatch('app/closeSideBar', { withoutAnimation: false });
    },

  },
  
  beforeMount() {
    window.addEventListener('resize', this.$_resizeHandler);
  },
  
  beforeDestroy() {
    window.removeEventListener('resize', this.$_resizeHandler);
  },
  
  mounted() {
    const isMobile = this.$_isMobile();
    if(isMobile) {
      store.dispatch('app/toggleDevice', 'mobile');
      store.dispatch('app/closeSideBar', { withoutAnimation: true });
    }
  },
  
  methods: {

    /* **** _isMobile **** */
    $_isMobile() {
      const rect = body.getBoundingClientRect();
      return rect.width - 1 < WIDTH;
    },

    /* **** _resizeHandler **** */
    $_resizeHandler() {
      if(!document.hidden) {
        const isMobile = this.$_isMobile();
        store.dispatch('app/toggleDevice', isMobile ? 'mobile' : 'desktop');
        if(isMobile)
          store.dispatch('app/closeSideBar', { withoutAnimation: true });
      }
    },
    
  },
  
};
