export default {

  install: function(Vue) {

    /* **** $authInit **** */
    Vue.$authInit = Vue.prototype.$authInit = function() {
      if(this.$states.storage) {
        const auth = this.$cacheGet('auth');
        if(auth) {
          this.$authSet(JSON.parse(auth.data), false);
        }
      }
      console.log('A: AuthInit', this.$user.id);
    };

    /* **** $authSet **** */
    Vue.$authSet = Vue.prototype.$authSet = function(data, flag_save) {
      const save = typeof flag_save == 'undefined' || flag_save ? true : false;
      if(
        typeof data._token === 'string' &&
        data._token.length == 32 &&
        typeof data._time === 'string' &&
        (!this.$auth.token_time || data._time >= this.$auth.token_time) &&
        typeof data._user === 'object' &&
        typeof data._user.id === 'number' &&
        typeof data._card === 'object' &&
        typeof data._card.id === 'number'
      ) {
        if(save && this.$states.storage)
          this.$cacheSet(
            'auth',
            JSON.stringify({
              _token: data._token,
              _time: data._time,
              _user: data._user,
              _card: data._card
            }),
            data._time
          );
        methods.set.call(this, data._user, data._card);
        this.$filterUpdate('cards');
        if(typeof data._env === 'object') {
          if(typeof data._env.notifications === 'object')
            methods.setNotifications.call(this, data._env.notifications);
          if(typeof data._env.messages === 'object') {
            methods.setMessages.call(this, data._env.messages);
          }
        }
        this.$auth.token = data._token;
        this.$auth.token_time = data._time;
      }
    };

    /* **** $supSet **** */
    Vue.$supSet = Vue.prototype.$supSet = function(data) {
      var k = '';
      for(k in data) {
        if(Object.prototype.hasOwnProperty.call(data, k) && typeof this.$sup[k] !== 'undefined') {
          if(typeof data[k] === 'object') {
            this.$sup[k].splice(0, this.$sup[k].length);
            Array.prototype.push.apply(this.$sup[k], data[k]);
          }
          else {
            this.$sup[k] = data[k];
          }
          if(k == 'cards_ids')
            this.$filterBuild('cards');
        }
      }
    };

    /* **** $permit **** */
    Vue.$permit = Vue.prototype.$permit = function(permits) {
      var result = false;
      if(this.$user.id) {
        if(permits.indexOf('*') > -1) {
          result = true;
        }
        else {
          const len = permits.length;
          for(var i = 0; i < len; i++)
            if(this.$user.permissions.indexOf(permits[i]) > -1) {
              result = true;
              break;
            }
        }
      }
      return result;
    };

    /* **** $mode **** */
    Vue.$mode = Vue.prototype.$mode = function(modes) {
      return this.$user.id && (modes.indexOf('*') > -1 || modes.indexOf(this.$user.mode) > -1);
    };


    const methods = {

      /* **** set **** */
      set(user, card) {
        var k = '',
            s = '';
        for(k in user) {
          if(Object.prototype.hasOwnProperty.call(user, k) && typeof this.$user[k] !== 'undefined') {
            if(typeof user[k] === 'object') {
              this.$user[k].splice(0, this.$user[k].length);
              Array.prototype.push.apply(this.$user[k], user[k]);
            }
            else {
              this.$user[k] = user[k];
              //console.log(k, user[k]);
            }
          }
        }
        for(k in card) {
          if(Object.prototype.hasOwnProperty.call(card, k) && typeof this.$card[k] !== 'undefined') {
            if(typeof card[k] === 'object' && (k == 'settings' || k == '_data')) {
              for(s in card.settings)
                if(Object.prototype.hasOwnProperty.call(card.settings, s))
                  this.$card.settings[s] = card.settings[s];
            }
            else {
              this.$card[k] = card[k];
            }
          }
        }
      },

      /* **** setNotifications **** */
      setNotifications(notifications) {
        if(
          typeof notifications.amount === 'number' &&
          typeof notifications.pool === 'object'
        ) {
          //console.log('empty block');
        }
      },

      /* **** setMessages **** */
      setMessages(messages) {
        if(
          typeof messages.manager === 'number' &&
          typeof messages.lawyer === 'number' &&
          typeof messages.user === 'number' &&
          typeof messages.task === 'number'
        ) {
          this.$messages.manager = messages.manager;
          this.$messages.lawyer = messages.lawyer;
          this.$messages.user = messages.user;
          this.$messages.task = messages.task;
        }
      },

    };

  },

};
