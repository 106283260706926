import Vue from 'vue';

import './plugins/element.js';

import App from './App.vue';

/* **** styles **** */
import './styles/variables.css';
import './styles/index.css';
import './styles/fonts.css';
import './styles/icofont.css';

import './icons'; // icons

import store from './store';
import router from './router';
import './router/permissions.js';

Vue.config.productionTip = false;

/* engine */
import { start } from './engine/engine.js';

start({
  agent: 'app', 
  //version: '0.9.437', // production
  //version: '0.9.438',
  version: '0.9.531', // admin.digitender.ru
  //version: '0.9.551', // admin.dev.digitender.ru
  //version: '0.9.552'  // Сервер Фила
});

new Vue({
  store: store,
  router: router,
  render: h => h(App),
}).$mount('#app');
