<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  
  data() {
    return {
      redirect: undefined,
    }
  },

  computed: {
    
    user_id() {
      return this.$user.id;
    },
    
    startState() {
      return this.$states.start;
    },
    
  },

  mounted() {
    const root = this;
    root.$states.storage = root.$cacheTest();
    root.$authInit();
    root.$request({
      path: '/',
      data: {},
      call: [ root.init, root ],
      cache: false
    });
  },
  
  watch: {
    
    user_id(v) {
      console.log('U: UserIdChange', v, this.$states.start);
      if(this.$states.start && !this.$states.lock) {
        if(v) {
          this.gotoRedirect();
        }
        else {
          this.$router.push({ path: '/auth' });
        }
      }
    },
    
    startState(v) {
      console.log('S: Start', this.$user.id);
      if(v && this.$user.id) {
        this.gotoRedirect();
      }
    },
    
    // $route: {
    //   handler: function(route) {
    //     console.log('R', route.path, route.query);
    //     // const query = route.query
    //     // if (query) {
    //     //   this.redirect = query.redirect
    //     //   this.otherQuery = this.getOtherQuery(query)
    //     // }
    //   },
    //   immediate: true
    // },

  },

  methods: {

    /* gotoRedirect */
    gotoRedirect() {
      if(this.$client.redirect.path === '/auth') {
        this.$client.redirect.path = '/';
        this.$client.redirect.query = {};
      }
      if(this.$client.redirect.path) {
        this.$router.push({
          path: this.$client.redirect.path,
          query: this.$client.redirect.query,
        });
        this.$client.redirect.path = '/';
        this.$client.redirect.query = {};
      }
    },

    /* init */
    init(status, data) {
      if(status == 200 && this.$user.id) {
        this.$communication([ 'socket' ]);
        this.$supplement([ 'cards' ], this.start);
      }
      else {
        this.$states.start = true;
      }
    },

    start() {
      this.$states.start = true;
    },
    
    // getOtherQuery(query) {
    //   return Object.keys(query).reduce((acc, cur) => {
    //     if (cur !== 'redirect') {
    //       acc[cur] = query[cur]
    //     }
    //     return acc
    //   }, {})
    // },

  },
};
</script>
