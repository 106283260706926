import io from 'socket.io-client';

var socket = null;

export default {

  install: function(Vue) {

    /* **** $initFcm **** */
    Vue.$initFcm = Vue.prototype.$initFcm = function(options) {
      options['fbPlugin'] = window.FirebasePlugin;
      methods.initFcmPush.call(this, options);
    };

    /* **** $initSocket **** */
    Vue.$initSocket = Vue.prototype.$initSocket = function(options) {
      methods.initSocket.call(this, options);
    };

    const methods = {

      /* **** FCM **** */

      /* **** initFcmPush **** */
      initFcmPush(options) {
        if(typeof device === 'undefined') {
          return;
        }
        if(device.platform === 'iOS') {
          options.fbPlugin.hasPermission(
            (hasPermission) => {
              if(hasPermission) {
                methods.getMyToken.call(this, options);
                return;
              }
              options.fbPlugin.grantPermission(
                (hasPermission) => {
                  if(hasPermission) {
                    methods.getMyToken.call(this, options);
                    return;
                  }
                }
              );
            }
          );
        }
        else {
          methods.getMyToken.call(this, options);
        }
      },

      /* **** getMyToken **** */
      getMyToken(options) {
        options.fbPlugin.onTokenRefresh(
          (token) => {
            if(token) {
              options.onConnect.call(this, token);
              methods.subscribeNewMsg.call(this, options);
            }
            else {
              options.fbPlugin.getToken(
                (token) => {
                  options.onConnect.call(this, token);
                  methods.subscribeNewMsg.call(this, options);
                },
                (error) => {
                  // error
                }
              );
            }
          },
          (error) => {
            // error
          }
        );
      },

      /* **** subscribeNewMsg **** */
      subscribeNewMsg(options) {
        options.fbPlugin.onMessageReceived(
          (message) => {
            if(typeof options.onNotify === 'function')
              options.onNotify.call(this, message);
          },
          (error) => {
            // error
          }
        );
      },

      /* **** WebSocket **** */

      /* **** initSocket **** */
      initSocket(options) {
        const root = this;
        socket = io(options.url);
        socket.on('connect',
          () => {
            socket.emit('join',
              JSON.stringify({
                token: options.token
              })
            );
            if(options.onConnect)
              options.onConnect.call(root);
          }
        );
        socket.on('disconnect',
          () => {
            if(options.onDisconnect)
              options.onDisconnect.call(root);
          }
        );
        socket.on('notification',
          (data, callback) => {
            if(typeof callback === 'function')
              callback(data.id);
            if(options.onNotify)
              options.onNotify.call(this, data);
          }
        );
    },

    };

  },

};
