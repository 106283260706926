export default {

	install: function(Vue) {

		/* **** cacheTest **** */
		Vue.$cacheTest = Vue.prototype.$cacheTest = function() {
			try {
				localStorage.setItem('___test___', 'test');
				localStorage.removeItem('___test___');
			}
			catch(e) {
				return false;
			}
			return true;
		};

		/* **** cacheGet **** */
		Vue.$cacheGet = Vue.prototype.$cacheGet = function(k) {
			const data = localStorage.getItem('___data___' + k);
			const time = localStorage.getItem('___time___' + k);
			if(data && time) {
				return { data: data, time: time };
			}
			return null;
		};

		/* **** cacheSet **** */
		Vue.$cacheSet = Vue.prototype.$cacheSet = function(k, d, t) {
			localStorage.setItem('___data___' + k, d);
			localStorage.setItem('___time___' + k, t);
		};

	},

};
